function setInputFormatter() {

	const inputElements = document.getElementsByTagName("input");
	for (const input of inputElements) {
		input.addEventListener("change", function() {
			this.value = this.value.trim();
		});
	}

	const allCapsElements = document.getElementsByClassName("all-caps");
	for (const allCaps of allCapsElements) {
		allCaps.addEventListener("input", function() {
			this.value = this.value.toUpperCase();
		});
	}

	// Excludes Ñ/ñ. Create another or modify this function to include these letters
	const lettersNumbersElements = document.getElementsByClassName("letters-numbers");
	for (const lettersNumbers of lettersNumbersElements) {
		["keypress", "keyup", "blur"].forEach(function(events) {
		    lettersNumbers.addEventListener(events, function(event) {
				this.value = this.value.replace(/[^A-Za-z0-9]/g, "");
	            if ((event.which < 48 || event.which > 57) && (event.which < 65 || event.which > 90) && (event.which < 97 || event.which > 122)) {
	                event.preventDefault();
	            }
			});
		});
	}

    const numbersElements = document.getElementsByClassName("numbers");
	for (const numbers of numbersElements) {
		["keypress", "keyup", "blur"].forEach(function(events) {
		    numbers.addEventListener(events, function(event) {
				this.value = this.value.replace(/[^\d].+/, "");
	            if (event.which < 48 || event.which > 57) {
	                event.preventDefault();
	            }
			});
		});
	}

}
